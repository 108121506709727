import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage2() {
  const question = {
    title: 'Frage 2 von 35',
    headline: 'Alter der Patienten',
    subline: 'Ich versorge am liebsten...',
  }

  const answers = [
    {
      name: 'Alter',
      id: 'Neugeborene',
      value: '... Neugeborene',
      desc: '(Geburt, bis vier 4 Wochen)',
    },
    {
      name: 'Alter',
      id: 'Kinder',
      value: '... Kleinkinder und Kinder',
      desc: '(0 - 13 Jahre)',
    },
    {
      name: 'Alter',
      id: 'Jugendliche',
      value: '... Jugendliche',
      desc: '(14 - 17 Jahre)',
    },
    {
      name: 'Alter',
      id: 'Erwachsene',
      value: '... Erwachsene',
      desc: '(18 - 64 Jahre)',
    },
    {
      name: 'Alter',
      id: 'Ältere',
      value: '... Ältere Menschen',
      desc: '(65 Jahre und älter)',
    },
    {
      name: 'Alter',
      id: 'Egal',
      value: 'Das ist mir egal',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-turkis-waves">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="turkis"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="multi"
              answers={answers}
              color="yellow"
              index={2}
            />
          </div>
        </section>

        <MatchingNavigation
          color="turkis"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-3`}
          prev={`${AREA_CARE_PATH}/spielen/frage-1`}
          index={2}
        />

        <Icon position="2" name="hourglass" />
      </main>
    </>
  )
}
